
import { defineComponent, onMounted } from "vue";
import MixedWidget2 from "@/components/widgets/mixed/Home.vue";
import Page from "@/layout/Page.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    MixedWidget2,
    Page,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Inicio");
    });
  },
});
